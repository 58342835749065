.auth-screen-bg {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--global-purple-4);
  background-image: url("../assets/bg/pexels-mali-maeder-142520.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: darken;
}

.form-wrapper {
  padding: 5rem 2rem;
  background: none;
  width: 30%;
  height: max-content;
  background: var(--global-white);
  border-radius: var(--border-radius-2);
}

.auth-form {
  /* border: 1px solid black; */
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
}

.auth-header,
.auth-form-group,
.auth-other-actions {
  width: 100%;
}

.auth-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.auth-form-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-group,
.input-row {
  width: 100%;
  text-align: left;
}

.input-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  align-content: center;
  column-gap: 0.8rem;
  row-gap: 0.6rem;
}

.input-row .input-col {
  display: flex;

  justify-content: center;
  align-items: center;
  width: 100%;
}

.input-group label {
  font-weight: 500;
  font-size: var(--font-normal-2);
}

/*Text and other inputs*/
.input-group .text-input-wrapper {
  margin: 0.2rem 0 1rem 0;

  width: 100%;
  border: 1px solid var(--global-gray-3);
  padding: 0rem 0;
  border-radius: var(--border-radius-5);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: var(--font-normal-2);
}

.input-group .textarea-input-wrapper {
  margin: 0.2rem 0 1rem 0;
  height: 4rem;
  width: 100%;
  border: 1px solid var(--global-gray-3);
  padding: 0rem 0;
  border-radius: var(--border-radius-2);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: var(--font-normal-2);
}

.text-input-wrapper input,
.text-input-wrapper select {
  width: 90%;
  padding: 0.6rem 0.4rem;
  color: var(--global-dark);
  font-weight: 600;
  background: none;
  border-radius: var(--border-radius-5);
  font-size: var(--font-normal);
}

.textarea-input-wrapper textarea {
  height: max-content;
  width: 90%;
  padding: 0.5rem 0.4rem;
}

.text-input-wrapper input:focus,
.text-input-wrapper input:active {
  background: none;
}

.input-group .profile-image-wrapper {
  margin: 0.2rem 0 1rem 0;

  width: 100%;
  border: 1px solid var(--global-gray-3);
  padding: 0.6rem 0;
  border-radius: var(--border-radius-5);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.input-group .other-image-wrapper {
  margin: 0.2rem 0 1rem 0;

  width: 100%;
  height: 8rem;

  border: 1px solid var(--global-gray-3);
  /* padding: 0.6rem 0; */
  border-radius: var(--border-radius-2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.input-group .other-image-wrapper img {
  border-radius: var(--border-radius-2);

  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*password inputs*/
.input-group .password-input-wrapper {
  margin: 0.2rem 0 1.5rem 0;

  width: 100%;
  border: 1px solid var(--global-gray-3);
  padding: 0rem 0;
  border-radius: var(--border-radius-5);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.5rem;
}

.password-input-wrapper input {
  width: 80%;
  padding: 0.6rem 0.4rem;
  color: var(--global-dark);
  font-weight: 600;
  border-radius: var(--border-radius-5);
}

.password-input-wrapper input:focus,
.password-input-wrapper input:active {
  background: none;
}
.submit-btn {
  background: var(--global-purple-2);
  color: var(--global-white);
  border-radius: var(--border-radius-5);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding: 0.8rem 0;
  font-size: var(--font-usable);
}

.submit-btn:hover {
  filter: brightness(1.7);
}

.auth-other-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0.5rem;
  font-size: var(--font-normal);
}

.auth-other-actions button {
  color: var(--global-dark);
  font-size: var(--font-normal);
}

.auth-other-actions .link {
  color: var(--global-purple-2);
  font-size: var(--font-normal);
}
/*Mobile and tablet responsiveness*/
@media (min-width: 320px) and (max-width: 420px) {
  .form-wrapper {
    padding: 5rem 0.8rem;
    background: none;
    width: 90%;
    height: max-content;
    background: var(--global-white);
    border-radius: var(--border-radius-2);
  }
  .auth-header h3 {
    font-size: var(--font-normal-2);
  }

  .auth-form {
    /* border: 1px solid black; */
    width: 98%;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;
  }

  .auth-header,
  .auth-form-group,
  .auth-other-actions {
    width: 100%;
  }
}
