/*Cart modal from drop down*/
.products-search {
  position: fixed;
  top: 7%;
  right: 33%;
  z-index: 99;
  width: 20%;
  max-height: 20rem;
  padding: 1rem 0.6rem;
  border-radius: var(--border-radius);
  box-shadow: var(--common-shadow-3);
  background: var(--global-white) !important;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 1rem;

  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  -o-transition: color 0.3s ease-out, background 0.5s ease-in-out;
  -ms-transition: color 0.3s ease-out, background 0.5s ease-in-out;
  -moz-transition: color 0.3s ease-out, background 0.5s ease-in-out;
  -webkit-transition: color 0.3s ease-out, background 0.5s ease-in-out;
  transition: color 0.3s ease-out, background 0.5s ease-in-out;
}

/* .products-search::-webkit-scrollbar {
  display: none;
} */

.products-search .top {
  width: 95%;
  padding: 0.2rem;
  border-bottom: 1px solid var(--global-gray);
}

.products-search .top span {
  text-align: left;
  float: left;
}
.products-search .top button {
  text-align: right;
  float: right;
  right: 0;
}

.search-notif {
  font-style: italic;
  font-size: var(--font-normal);
  font-weight: 500;
}

.search-loader {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-item {
  width: 95%;
  font-size: var(--font-normal-2);
  padding: 0.3rem 0.2rem;
  box-shadow: var(--common-shadow);
  border-bottom: 1px solid var(--global-gray-4);
}

.search-item:hover {
  transform: scale(0.9);
}

/*Mobile and tablet responsiveness*/
@media (min-width: 320px) and (max-width: 420px) {
  .products-search {
    top: 7%;
    left: 5%;
    z-index: 99;
    width: 80%;
  }
}
