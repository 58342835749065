.terms-container,
.terms-header {
  width: 100%;
  height: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1.5rem;
  padding: 2rem 0;
}
.terms-container {
  width: 100%;
  height: 65%;
  row-gap: 1.5rem;
}

.terms-header {
  row-gap: 0.5rem;
  width: 100%;
  display: flex;
  margin: 0 0 2rem 0;
}

.terms-header-sup {
  row-gap: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.terms-header-sup img {
  width: 10rem;
  height: 5rem;
  object-fit: contain;
}

.terms-header-sup .close-btn:hover .close-icon,
.terms-header-sup .close-btn:active .close-icon {
  transform: rotate(3.142rad);
  transform: scale(1.1);
  filter: 0.8;
}

.terms-body {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 2rem;
  height: max-content;
  letter-spacing: 0.015rem;
}
.terms-body p,
.terms-body h4 {
  margin: 0;
  padding: 0;
}
