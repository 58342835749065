/*Regular fonts*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cuprum:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Merriweather+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Signika:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cuprum:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Merriweather+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Signika:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cuprum:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Merriweather+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Signika:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cuprum:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Merriweather+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Signika:wght@300;400;500;600;700&display=swap");
/*Cursive*/
@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amita:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courgette&display=swap");

:root {
  --rmsc-main: #4285f4;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 15px; /* Radius */
  --rmsc-h: 38px; /* Height */
  --rmsc-w: 120px; /* Height */

  --global-font-family: "Roboto", sans-serif;

  --global-regular-font-family-1: "Cuprum", sans-serif;
  --global-regular-font-family-2: "Merriweather Sans", sans-serif;
  --global-regular-font-family-3: "Signika", sans-serif;

  --global-font-family-cursive: "Cedarville Cursive", cursive;
  --global-font-family-cursive-2: "Pacifico", cursive;
  --global-font-family-cursive-3: "Amita", cursive;
  --global-font-family-cursive-4: "Courgette", cursive;

  /* colors */
  --danger: rgb(202, 2, 2);
  --success: rgb(0, 183, 15);
  --tentative: var(--global-dark-2);

  --global-white: #fff;
  --global-white-2: #f3f3f3;
  --global-white-3: #f0f0f0;

  --global-black: #000000;
  --global-dark: rgb(54, 54, 54);
  --global-dark-2: rgb(106, 106, 106);
  --global-dark-3: rgb(146, 146, 146);

  --global-gray: #dddddd;
  --global-gray-2: #ddddddd5;
  --global-gray-3: #ddddddf7;
  --global-gray-4: #f7f7f7f7;
  --global-gray-5: #a19c9b;
  --global-gray-6: #e6e6e6;

  --global-silver: #8f8c9d;

  --global-cream: #eaeced;

  --global-purple: #bf00fe;
  --global-purple-2: #5a0079;
  --global-purple-3: #be00fe60;
  --global-purple-4: #8600b360;
  --global-purple-5: #edb5ff4a;
  --global-purple-6: #3d155f;
  --global-purple-dim: #74009a2e;
  --peach-blend: #df678c;
  --peach-wine: #da0019;

  /*colors linear*/
  --steel-gray-1: #485563; /* fallback for old browsers */
  --steel-gray-2: -webkit-linear-gradient(to right, #485563, #29323c);
  --steel-gray-3: linear-gradient(to right, #485563, #29323c);
  --global-portrait: linear-gradient(to right, #eef2f3, #8e9eab);
  --global-deep-space: linear-gradient(to right, #000000, #434343);
  --global-metal: linear-gradient(to right, #ffffff, #abbaab);
  --global-argon-purp-1: linear-gradient(
    to left,
    #03001e,
    #7303c0,
    #ec38bc,
    #c9c9c9
  );
  --global-aubergine-purp-2: linear-gradient(to right, #aa076b, #61045f);
  /* fonts */
  --font-normal: 0.8rem;
  --font-normal-2: 0.9rem;

  --font-usable: 1rem;
  --font-medium: 1.2rem;
  --font-large: 1.5rem;
  --font-larger: 1.9rem;

  /* shadows */
  --common-shadow: 2px 2px 4px 3px rgba(240, 240, 240, 0.592);
  --common-shadow-2: 1px 2px 2px 3px rgba(232, 232, 232, 0.764);
  --common-shadow-3: 1px 2px 2px 3px rgba(226, 226, 226, 0.84);
  --card-shadow-1: 0.5px 2px 3px 6px rgba(246, 246, 246, 0.673);
  --card-shadow-2: 0.5px 2px 3px 6px rgba(250, 250, 250, 0.766);
  --button-shadow-3: 1px 1px 10px 12px rgba(141, 141, 141, 0.759);

  /* radius */
  --border-radius: 10px;
  --border-radius-2: 15px;
  --border-radius-3: 25px;
  --border-radius-4: 50px;
  --border-radius-5: 99px;
}

.danger {
  color: var(--danger);
}
.success {
  color: var(--success);
}
.tentative {
  color: var(--global-purple-2);
}
.small-text {
  font-size: var(--font-normal);
}

.form-errors {
  color: var(--danger);
  font-size: var(--font-normal);
  margin: 0.8rem 0;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: var(--global-regular-font-family-3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  transition: 0.3s;
  background-color: var(--global-white);
}

input,
select,
textarea,
button,
a,
img {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  background: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

textarea {
  font-family: var(--global-regular-font-family-1);
  resize: none;
}

button:disabled,
input:read-only,
select:read-only {
  background: var(--global-gray-4);
  opacity: 0.5;
}

img {
  margin: 0;
}

.icon,
span,
section {
  margin: 0;
  padding: 0;
}

nav {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

label {
  padding: 0;
  margin: 0;
}
a,
link,
button,
.link {
  margin: 0;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s;
  color: var(--global-black);
}

a:hover,
link:hover,
button:hover {
  transition: 0.3s;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo {
  width: 3rem;
  height: auto;
}

/* Scrollbar styles */
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: var(--global-white);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 45px;
  background: var(--global-purple-2);
}

::-webkit-scrollbar-thumb:hover {
  transform: scale(1.02);
  filter: brightness(1.5);
}

/*search bar*/
.search-container {
  padding: 0.5rem;
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.8rem;
  border-radius: var(--border-radius-4);
  border: 1px solid var(--global-purple-3);
}

.search-container {
  width: 20rem;
}

.search-container input {
  width: 85%;
}

/*Pre -loaders, tables and button loaders*/
.preloader-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.preloader-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40rem;
  height: auto;
}

.preloader-logo img {
  width: 50%;
  height: auto;
}

.animation {
  width: 5rem;
  height: auto;
}

/*Data loader*/
.data-loader {
  padding: 2rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
}

/* Page not found */
.page-not-found {
  background-color: var(--global-purple-4);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0.5rem;
  width: 100%;
  height: 90vh;
  padding: 3rem 0 0.7rem 0;
}

.page-not-found img {
  width: 30rem;
  height: auto;
}

.page-not-found .info {
  text-align: center;
  width: 30%;
  font-size: 1.5rem;
  font-family: var(--global-font-family-cursive-4);
  font-weight: bolder;
  color: var(--global-white);
}

/* No data */
.no-data {
  background: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0.5rem;
  width: 100%;
  height: max-content;
  padding: 3rem 0 0.7rem 0;
}

.no-data img {
  width: 15rem;
  height: auto;
}

.no-data .info {
  text-align: center;
  width: 30%;
  font-size: 1.2rem;
  font-family: var(--global-font-family-cursive-4);
  font-weight: bolder;
  color: var(--global-dark);
}

/*custom modal close btn*/
.close-container {
  width: 100%;
}

.modal-close-btn {
  margin: 0 0 -1.5rem 0;
  text-align: right;
  float: right;
}

.modal-close-btn:hover {
  transform: scale(1.05);
  color: var(--danger);
}

.custom-success-notification,
.custom-error-notification,
.custom-unknown-notification {
  background-color: var(--success);
  color: var(--global-white);
  font-size: 700;
  padding: 0.4rem 1.5rem;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 60%;
  height: max-content;
  border-radius: var(--border-radius-2);
}

.custom-success-notification {
  background-color: var(--success);
}

.custom-error-notification {
  background-color: var(--danger);
}
.custom-unknown-notification {
  background-color: var(--global-dark-2);
}

/*Multi select component*/
.rmsc {
}
/*Mobile and tablet responsiveness*/
@media (min-width: 320px) and (max-width: 420px) {
  html,
  body {
    transition: 0.15s;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  .preloader-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .preloader-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40rem;
    height: auto;
  }

  .preloader-logo img {
    width: 50%;
    height: auto;
  }
}

.card-preloader {
  width: 100%;
  backdrop-filter: blur(1rem);
  filter: blur(0.5rem);

  animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  -moz-animation: bounce 2s infinite;
  -o-animation: bounce 2s infinite;
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-5px);
  }
  60% {
    -webkit-transform: translateY(3px);
  }
}

@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-5px);
  }
  60% {
    -webkit-transform: translateY(3px);
  }
}

@-o-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-5px);
  }
  60% {
    -webkit-transform: translateY(3px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-5px);
  }
  60% {
    -webkit-transform: translateY(3px);
  }
}
