.account-management-layout {
  width: 95%;
  height: max-content;

  padding: 3rem 2rem 5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 2.5rem;
}

.settings-pane {
  width: 100%;
  height: max-content;

  display: grid;
  grid-template-columns: 20% 75%;
  column-gap: 2.5rem;
}

.settings-side-bar,
.settings-content-area {
  width: 100%;
  height: max-content;
  max-height: 35rem;
  padding: 2rem 0.5rem;
}

.settings-side-bar {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 3rem;
}

.settings-side-bar .settings-button-navs {
  width: 55%;
  height: max-content;
  display: inherit;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 1.5rem;
}

.settings-button-navs .nav-btn {
  width: 90%;

  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-usable);
  padding: 0.5rem 0;
}

.settings-button-navs .nav-btn span {
  width: 50%;
  text-align: left;
}
.settings-button-navs .nav-btn:hover {
  color: var(--global-dark-2);
  text-decoration: underline;
}

.settings-content-area {
  background-color: var(--global-white);
  border-radius: var(--border-radius);
  box-shadow: var(--card-shadow-1);
  display: flex;
  height: max-content;
  flex-direction: column;
  justify-content: flex-start;

  padding: 2rem 2rem 7rem 2rem;
}

/*Bio data styles*/
.bio-data-settings-container {
  width: 95%;
  padding: 0 1.5rem;
  display: grid;
  grid-template-columns: 45% 54%;
  column-gap: 1rem;
}

.bio-data-settings-container .profile-image-settings,
.bio-data-settings-container .profile-data-settings {
  height: 25rem;
}

.bio-data-settings-container .profile-image-settings {
  border-right: 1px solid var(--global-gray);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 3rem;
}

.profile-image-settings h4 {
  text-transform: uppercase;
}
.avatar {
  background: none;
  border-radius: var(--border-radius-5);
  border: 3px solid var(--global-gray);
  border-width: thin;
  box-shadow: var(--common-shadow);
  text-align: center;
  object-fit: contain;
}

.initials {
  width: 12.5rem;
  height: 12.5rem;
  background: var(--global-white);
  border-radius: var(--border-radius-5);
  border: 3px solid var(--global-gray);
  border-width: thin;
  box-shadow: var(--card-shadow);
  text-align: center;

  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.initials h2 {
  padding: 0;
  margin: 0;
  font-size: 5rem;
}

.selected-image {
  background-color: var(--global-purple);
  color: var(--global-white);
  cursor: pointer;
  font-weight: 600;
  padding: 0.5rem 1.2rem;
  width: max-content;
  border-radius: var(--border-radius);
  border: 1px solid var(--global-purple);
}

.selected-image:hover,
.change-profile-btn:hover,
.upload-profile-btn:hover {
  transform: scale(1.05);
}

.upload-actions-container {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* column-gap: 0.8rem; */
  align-items: center;
}

.change-profile-btn,
.upload-profile-btn {
  font-weight: 600;
  padding: 0.5rem 1.2rem;
  width: max-content;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  border: 1px solid var(--global-purple);
  cursor: pointer;
}

.change-profile-btn {
  border: 1px solid var(--global-purple);
  color: var(--global-purple);
}

.upload-profile-btn {
  background-color: var(--global-purple);
  color: var(--global-white);
}

.profile-data-settings,
.security-settings {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.bio-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}

.bio-form label {
  font-size: var(--font-normal);
}

.password-update-form {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 3rem;
}
/*Profile dropdown modal*/

.profile-modal {
  position: fixed;
  top: 7%;
  right: 1%;
  z-index: 99;
  width: 20%;
  max-height: max-content;
  padding: 1.3rem 0.8rem;
  border-radius: var(--border-radius);
  box-shadow: var(--card-shadow-1);
  background: var(--global-white) !important;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 1.5rem;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  -o-transition: color 0.3s ease-out, background 0.5s ease-in-out;
  -ms-transition: color 0.3s ease-out, background 0.5s ease-in-out;
  -moz-transition: color 0.3s ease-out, background 0.5s ease-in-out;
  -webkit-transition: color 0.3s ease-out, background 0.5s ease-in-out;
  transition: color 0.3s ease-out, background 0.5s ease-in-out;
}

.modal-basic-profile {
  width: 98%;
  height: max-content;
  display: grid;
  grid-template-columns: 25% 70%;
  align-items: center;
  column-gap: 1rem;
}

.modal-basic-profile img {
  width: 4.5rem;
  height: 4.5rem;
  object-fit: cover;
  -o-object-fit: cover;
  border-radius: var(--border-radius-5);
  box-shadow: var(--card-shadow-2);
}

.modal-basic-profile .modal-profile-avatar-avatar {
  width: 4.5rem;
  height: 4.5rem;
  object-fit: cover;
  -o-object-fit: cover;
  border: 3px solid var(--global-gray);
  border-radius: var(--border-radius-5);
  box-shadow: var(--card-shadow-2);
}
.modal-basic-profile .modal-profile-text {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 0.2rem;
}

.modal-profile-text a {
  font-size: var(--font-normal);
  color: var(--global-purple);
}

.modal-account-links {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 0.8rem;
  padding: 1rem 0;
  border-top: 1px solid var(--global-gray);
  border-bottom: 1px solid var(--global-gray);
}

.modal-account-links .link,
.modal-profile-logout {
  width: 50%;
  display: grid;
  grid-template-columns: 15% 70%;
  column-gap: 1.5rem;
  align-content: center;
}

.modal-account-links .link,
.modal-profile-logout {
  color: var(--global-dark-2);
  text-align: left;
}

.modal-account-links .link:hover,
.modal-profile-logout:hover {
  color: var(--global-black);
}

/*Mobile menu drawer*/
.mobile-drawer {
  z-index: 999;
  transition: 0.3s;
  height: max-content;
  width: 50%;
  position: fixed;
  right: 2%;
  top: 8%;
  padding: 1.5rem 0.8rem;
  background: var(--global-white);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: var(--border-radius) 0 0 var(--border-radius);
  box-shadow: var(--common-shadow);
  row-gap: 1rem;
}

.mobile-drawer .profile {
  padding-bottom: 0.5rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid var(--global-gray);
}

.mobile-drawer .profile .modal-profile-avatar {
  cursor: pointer;
  width: 4rem;
  height: 4rem;

  object-fit: cover;
  -o-object-fit: cover;
  border-radius: var(--border-radius-5);
  border: 1px solid var(--global-gray);
}

.mobile-drawer .profile div {
  width: 4rem;
  height: 4rem;
  background: var(--global-white);
  border-radius: var(--border-radius-5);
  border: 3px solid var(--global-gray);
  border-width: thin;
  box-shadow: var(--card-shadow-2);
  text-align: center;

  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: var(--font-large);
  font-weight: 700;
}

.mobile-drawer .profile span {
  display: flex;
  align-items: center;
}
.mobile-drawer .drawer-navigations {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 0.8rem;
  padding: 0.5rem;
}

.mobile-drawer .drawer-navigations .notification-badge {
  display: block;
  padding: 0.1rem 0.4rem;
  background: var(--global-purple-2);
  color: var(--global-white);
  border-radius: var(--border-radius-5);
  font-size: var(--font-normal);
}

.mobile-drawer .drawer-navigations .link {
  font-weight: 600;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/*Mobile and tablet responsiveness*/
@media (min-width: 320px) and (max-width: 420px) {
  .account-management-layout {
    width: 95%;
    height: max-content;

    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2.5rem;
  }

  .settings-pane {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5.5rem;
  }

  .settings-side-bar,
  .settings-content-area {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 0.5rem;
  }

  .settings-side-bar h3 {
    display: none;
  }

  .settings-side-bar {
    width: 100%;
    gap: 0.8rem;
    border-bottom: 1px solid var(--global-gray);
  }

  .settings-side-bar .settings-button-navs {
    width: 100%;
    height: max-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.8rem;
  }

  .settings-button-navs .nav-btn {
    width: 100%;
    font-weight: 600;
    justify-content: center;
    border: 1px solid var(--global-purple);
    border-width: thin;
    border-radius: var(--border-radius);
  }

  .settings-button-navs .nav-btn span {
    text-align: center;
  }
  .settings-button-navs .nav-btn:hover,
  .settings-button-navs .nav-btn:focus {
    background: var(--global-purple-2);
    color: var(--global-white);
    text-decoration: none;
  }

  .settings-content-area {
    height: max-content;
    row-gap: 1rem;
    padding: 5rem 2rem 5rem 2rem;
  }

  /*Bio data styles*/
  .bio-data-settings-container {
    /* width: 95%; */
    padding: 2rem 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bio-data-settings-container .profile-image-settings,
  .bio-data-settings-container .profile-data-settings {
    height: max-content;
  }

  .bio-data-settings-container .profile-image-settings {
    padding: 0.8rem 0;
    width: 100%;
    border-right: none;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1.5rem;
  }

  .upload-actions-container {
    width: 98%;
    column-gap: 0.5rem;
  }

  .upload-actions-container .change-profile-btn,
  .upload-actions-container .upload-profile-btn {
    font-size: 0.75rem;
    text-align: center;
    padding: 0.5rem 0.4rem;
  }

  .profile-data-settings,
  .security-settings {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 0.8rem 0;
  }

  .profile-data-settings {
    border-top: 1px solid var(--global-gray);
  }

  .bio-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0.2rem;
  }

  .bio-form label {
    font-size: var(--font-normal);
  }

  .password-update-form {
    padding-top: -2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 3rem;
  }
}
