.cart-page-layout {
  width: 96%;
  padding: 5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 2.5rem;
  height: max-content;
}

.cart-container {
  padding: 1rem 0.8rem;
  width: 98%;
  height: 35rem;
  border-radius: var(--border-radius);
  box-shadow: var(--card-shadow-1);
  background: var(--global-white);
  color: #000;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.cart-actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cart-container::-webkit-scrollbar {
  display: none;
}

.cart-actions .actions {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.checkout-btn {
  width: max-content;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 2rem;
  border-radius: var(--border-radius-5);
  border: 1px solid var(--global-dark);
  border-width: thin;
  font-weight: 600;
  margin: 0.5rem 0;
}

.checkout-btn:hover {
  background: var(--global-dark);
  color: var(--global-white);
}

.cart-container .no-cart-data {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  row-gap: 1rem;
  width: 50%;
  height: max-content;
  font-style: italic;
  font-size: var(--font-normal);
}

.cart-container .no-cart-data img {
  width: 7rem;
  height: auto;
}
.cart-header,
.cart-items > .cart-item {
  width: 95%;
  border-bottom: 1px solid var(--global-gray);
  display: grid;
  grid-template-columns: 7.5% 20% 25% 20% 10% 10% 7.5%;
  padding: 1rem 0;
  column-gap: 0.25rem;
}

.cart-item .mobile-product-name {
  display: none;
}

.cart-header {
  font-weight: 600;
  color: var(--global-black);
  font-size: var(--font-usable);
}

.cart-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--global-dark);
  font-size: var(--font-normal);
}

.cart-header span,
.cart-items span {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.cart-header .qty-heading,
.cart-header .price-heading,
.cart-item .item-qty-actions,
.cart-item .cart-item-price,
.cart-header .setting-heading,
.cart-item .cart-item-misc-actions {
  justify-content: center;
}

.cart-items .cart-item .item-name .item-image-wrapper {
  width: 50%;
  height: 5rem;
  border-radius: var(--border-radius);
}

.cart-item .item-name .item-image-wrapper .cart-product-image {
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
  object-fit: cover;
}

/*----Interested products styles-----*/
.cart-interested-products {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 1.5rem;
}

.cart-all-interested-products {
  width: 100%;
  height: max-content;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 1rem;
  row-gap: 1.2rem;
  padding: 0 0 0.5rem 0;
  border-bottom: 1px solid var(--global-gray);
}

.cart-all-interested-products .product-card {
  width: 98%;
  height: 18.5rem;
  background: var(--global-white);
  color: var(--global-dark);
  box-shadow: var(--card-shadow-1);
  display: grid;
  grid-template-rows: 75% 25%;
  row-gap: 0.5rem;
  text-decoration: none;
  border-radius: var(--border-radius);
}

.cart-all-interested-products .product-card img {
  width: 100%;
  height: 100%;
  border-bottom: 1px solid var(--global-gray);
  object-fit: cover;
  -o-object-fit: cover;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
}

.cart-all-interested-products .product-card .product-sub {
  width: 95%;
  padding: 0.35rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 0.3rem;
}

.cart-all-interested-products .product-card .product-sub .top,
.cart-all-interested-products .product-card .product-sub .bottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cart-all-interested-products .product-card .product-sub .bottom {
  align-items: center;
}

.cart-all-interested-products
  .product-card
  .product-sub
  span
  span:nth-child(1) {
  font-size: var(--font-normal);
  font-weight: 600;
}

.product-sub span span:nth-child(2) {
  font-size: var(--font-normal);
  font-weight: 500;
}

.add-to-cart {
  width: max-content;
  text-align: center;
  background: var(--global-purple-2);
  color: var(--global-white);
  padding: 0.4rem 0.4rem 0.2rem 0.4rem;
  border-radius: var(--border-radius-5);
}

.add-to-cart:hover {
  transform: scale(1.05);
  filter: brightness(1.4);
}

.product-card:hover,
.product-card:hover > img {
  border-radius: var(--border-radius);
  background: var(--global-white-3);
  transform: scale(1.01);
}

/*Cart modal from drop down*/
.cart-modal {
  position: fixed;
  top: 7%;
  right: 1%;
  z-index: 99;
  width: 30%;
  max-height: 20rem;
  padding: 1rem 0.6rem;
  border-radius: var(--border-radius);
  box-shadow: var(--card-shadow-1);
  background: var(--global-white) !important;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 1.5rem;

  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  -o-transition: color 0.3s ease-out, background 0.5s ease-in-out;
  -ms-transition: color 0.3s ease-out, background 0.5s ease-in-out;
  -moz-transition: color 0.3s ease-out, background 0.5s ease-in-out;
  -webkit-transition: color 0.3s ease-out, background 0.5s ease-in-out;
  transition: color 0.3s ease-out, background 0.5s ease-in-out;
}

.cart-modal::-webkit-scrollbar {
  display: none;
}
.cart-modal-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 0;
  border-bottom: 1px solid var(--global-gray);
}

.cart-modal-header h4 {
  display: flex;
  align-items: center;
}

.cart-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 0.5rem;
}

.modal-cart-item {
  width: 100%;
  display: grid;
  grid-template-columns: 20% 25% 20% 20% 10%;
  column-gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--global-white-3);
  font-size: var(--font-normal);
  font-weight: 600;
}

.modal-cart-item .modal-cart-item-detail {
  display: flex;
  flex-direction: column;
}

.modal-cart-item img {
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  -o-object-fit: cover;
  border-radius: var(--border-radius);
}

.modal-cart-checkout-btn {
  width: 95%;
  text-align: center;
  align-self: center;
  padding: 0.5rem;
  background-color: var(--global-dark);
  color: var(--global-white);
  font-weight: 600;
  border-radius: var(--border-radius);
}

.modal-cart-checkout-btn:hover {
  filter: brightness(0.6);
  transform: scale(1.02);
}

/*Mobile & Tablet responsive styles*/
@media (min-width: 320px) and (max-width: 420px) {
  .cart-page-layout {
    width: 98%;
    padding: 5rem 0.5rem;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .cart-container {
    width: 99%;
    height: max-content;
    row-gap: 1rem;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0.3rem;
  }

  .cart-actions {
    width: 98%;
    flex-direction: column;
    row-gap: 0.8rem;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .cart-actions .actions {
    width: 97%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cart-header {
    display: none;
  }

  .cart-items {
    width: 98%;
    font-size: var(--font-normal-2);
    row-gap: 0.8rem;
    padding: 1rem 0;
    border-top: 1px solid var(--global-gray);
  }

  .cart-items > .cart-item {
    width: 100%;
    border-bottom: 5px solid var(--global-gray-2);
    display: grid;
    grid-template-columns: 55% 45%;
    justify-items: center;
    align-items: center;
    padding: 0.5rem 0.5rem;
    gap: 0.5rem;
  }

  .cart-item span:first-child,
  .cart-item .cart-item-description,
  .cart-item .view-cart-item-details,
  .cart-item .desktop-product-name {
    display: none;
  }

  .cart-item .mobile-product-name {
    display: block;
    font-weight: 600;
    font-size: var(--font-medium);
  }
  .cart-product-image {
    width: 100%;
    height: auto;
    border-radius: var(--border-radius);
  }

  .cart-item span .cart-action {
    width: 100%;
    background: var(--global-white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem 0;
    border-radius: none;
    border: none;
  }

  .cart-item span .cart-action .unit-action {
    font-size: var(--font-large);
    color: var(--global-white);
    background: var(--global-purple-2);
    padding: 0.2rem 0.6rem;
    border-radius: var(--border-radius-5);
  }

  .cart-item span .cart-action .unit-action:first-child {
    padding: 0.2rem 0.75rem;
  }
  .cart-item span .cart-action .purchase-unit {
    color: var(--global-black);
    font-size: var(--font-medium);
    font-weight: 700;
    text-align: center;
  }

  .cart-item .cart-item-delivery,
  .cart-item .cart-item-price,
  .cart-item .cart-item-misc-actions {
    width: 100%;
  }

  .cart-items span:last-child {
    align-items: flex-end;
    justify-content: flex-end !important;
    font-size: var(--font-large);
  }
  /*----Interested products styles-----*/

  /*Interested prroducts*/
  .cart-all-interested-products {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0.5rem;
    row-gap: 1.2rem;
    padding: 0 0 1rem 0;
    border-bottom: 1px solid var(--global-gray);
  }

  .cart-all-interested-products .product-card {
    width: 98%;
    height: 15.5rem;
    background: var(--global-white);
    color: var(--global-dark);
    box-shadow: var(--card-shadow-1);
    display: grid;
    grid-template-rows: 75% 25%;
    row-gap: 0.5rem;
    text-decoration: none;
    padding: 0 0 0.5rem 0;
  }
}
