* {
  transition: 0.3s;
}
/* main customer layout styles*/
.customer-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 99.5vw;
  height: max-content;
  background: none;
}

.header,
.banner,
.sub-header,
.sub-header-scroll {
  width: 100%;
  display: flex;
  z-index: 99;
}

.footer {
  bottom: 0;
  width: 100%;
  display: flex;
  z-index: 80;
}

/* Header styles */

.header {
  flex-direction: column;
}

.banner,
.sub-header {
  flex-direction: row;
  padding: 0.6rem 0;
  box-shadow: var(--common-shadow);
  align-items: center;
  background: var(--global-white);
}

.sub-header-scroll {
  display: none;
}

.banner,
.sub-header {
  justify-content: space-between;
}

.banner {
  position: fixed;
  top: 0;
}

.sub-header {
  position: fixed;
  top: 0%;
}

.sub-header .logo,
.sub-header-scroll .logo {
  width: 9rem;
  height: auto;
}

.modal-drawer-btn {
  display: none;
}

.sub-header .sub-header-right,
.sub-header-scroll .sub-header-right,
.page-nav-links,
.banner-nav-links {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  column-gap: 1rem;
}

.page-nav-links .link,
.banner-nav-links .link {
  color: var(--global-dark);
  font-size: var(--font-normal);
  text-align: center;
  display: flex;
  align-items: center;
  transition: 0.3s;
}

.page-nav-links .link span {
  padding: 0.4rem;

  /* margin: 0 0 0.5rem 0; */
}
.page-nav-links .link:hover {
  color: var(--global-gray-2);
  /* filter: brightness(1.5); */
}

.page-nav-links .link:nth-child(even) {
  border-right: 1px solid var(--global-gray);
  padding-right: 0.5rem;
}

.page-nav-links .link:last-child {
  border-right: none;
}

.notification-badge {
  padding: 0.2rem 0.4rem;
  background: var(--global-purple-2);
  color: var(--global-white);
  border-radius: var(--border-radius-5);
}

.page-nav-links .avatar-container {
  width: max-content;
  height: max-content;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 0.5rem 0 0;
}

.page-nav-links .avatar-container .user-avatar {
  cursor: pointer;
  width: 100%;
  height: 100%;

  object-fit: cover;
  -o-object-fit: cover;
  border-radius: var(--border-radius-5);
}

.page-nav-links .avatar-container .user-avatar-placeholder {
  cursor: pointer;
  width: 100%;
  height: 100%;

  background: var(--global-white);
  border-radius: var(--border-radius-5);
  border: 3px solid var(--global-gray);
  border-width: thin;
  box-shadow: var(--card-shadow-2);
  text-align: center;

  padding: 0 0.6rem;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: var(--font-normal-2);
  font-weight: 700;
}

/*Landing area container styles*/
.landing-products-section {
  width: 100%;
  padding: 0 0 1.5rem 0;
}

/*Cart quantity controller*/

.cart-action {
  width: 50%;
  background: var(--global-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 0.75rem;
  border-radius: var(--border-radius);
  border: 0.075rem solid var(--global-purple-6);
}

.cart-action .unit-action {
  font-size: var(--font-large);
  font-weight: 700;
  text-align: center;
  color: var(--global-purple);
  background: none;
  cursor: pointer;
  text-align: center;
}

.cart-action .purchase-unit {
  color: var(--global-dark);
  font-size: var(--font-medium);
  font-weight: 700;
  text-align: center;
  align-self: center;
  text-decoration: underline;
}

/* Footer styles */

.footer {
  box-shadow: var(--card-shadow-1);
  border-top: 1px solid var(--global-gray);
  padding: 2.5rem 0;
  flex-direction: column;
  row-gap: 1rem;
  justify-content: center;
  align-items: center;
  height: max-content;
  bottom: 0;
}

.footer-top {
  width: 95%;
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  height: max-content;
  padding-bottom: 2rem;
  border-bottom: 1px solid var(--global-gray);
}

.footer .footer-top .secondary-logo {
  width: 12rem;
  height: auto;
  padding-right: 1rem;
  border-right: 1px solid var(--global-gray);
}

.footer-navigations {
  width: 80%;
  height: max-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
}

.footer-navigations .footer-sections {
  width: max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}

.footer-navigations .footer-sections .footer-links {
  height: max-content;
  width: 14rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 0.7rem;
}

.footer-links .footer-link {
  font-size: var(--font-normal);
  color: var(--global-dark-2);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer-delivery-locale {
  width: 90%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  justify-content: center;
  align-items: center;
  height: max-content;
  padding-bottom: 2rem;
  border-bottom: 1px solid var(--global-gray);
}

.footer-delivery-locale .footer-links {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.copyright {
  margin: 1rem 0;
  padding: 0.5rem;

  font-weight: 600;
  font-size: var(--font-normal-2);
}

#loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 200px;
  height: 200px;
  border: 5px solid var(--border-color-dark-mode);
  border-style: double;
  border-radius: 100%;
  margin: 20% auto;

  -webkit-animation: bounce 3s infinite ease-in-out;
  -o-animation: bounce 3s infinite ease-in-out;
  -ms-animation: bounce 3s infinite ease-in-out;
  -moz-animation: bounce 3s infinite ease-in-out;
  animation: bounce 3s infinite ease-in-out;
}

/*bottom pagination*/
.shopping-pagination {
  width: 98%;
  height: max-content;
  padding: 0.4rem 0.8rem;

  border-radius: var(--border-radius);
  box-shadow: var(--card-shadow-1);
  background: #fff !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-description {
  font-size: var(--font-normal);
}

.data-number {
  font-size: var(--font-usable);
  font-weight: 600;
}

.pagination__section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  margin: 0 0;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: max-content;
  text-align: right;
  list-style: none;

  list-style-type: none;
}

.pagination__section .page-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  font-weight: 600;
  text-decoration: none;
  list-style: none;
  list-style: none;
  list-style-type: none;
  color: var(--global-dark);
}

.pagination_arrows {
  color: rgba(76, 76, 76, 0.755);
  list-style: none;

  list-style-type: none;
}

.active-page {
  background: var(--global-white);
  border-radius: var(--border-radius-5);
  border: 1px solid var(--global-gray);
  border-width: thin;
  padding: 0.4rem 0.5rem;
  box-shadow: var(--card-shadow-2);
}

.inactive-page {
  color: var(--global-purple-2);
}

/*Mobile and tablet responsiveness*/
@media (min-width: 320px) and (max-width: 420px) {
  .sub-header .logo,
  .sub-header-scroll .logo {
    width: 5rem;
    height: auto;
  }

  .customer-layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 99%;
    height: max-content;
    background: none;
    margin: 0;
  }

  /*Header*/
  .header {
    width: 100%;
    background-color: #fff;
    background: #fff;
    box-shadow: none !important;
  }

  .sub-header {
    width: 98%;
    box-shadow: none !important;
    background-color: #fff;
    background: #fff;
    display: flex;
    justify-content: space-between;
  }

  .sub-header-scroll {
    z-index: 9;
    width: 82%;
    display: flex;
    flex-direction: row;
    padding: 0.6rem 0;
    box-shadow: var(--common-shadow);
    align-items: center;
    background: var(--global-white) !important;
    background-color: var(--global-white) !important;
  }

  .modal-drawer-btn {
    z-index: 999;
    display: block;
    padding: 0.3rem 0.4rem 0.25rem 0.4rem;
    border-radius: var(--border-radius);
    border: 1px solid var(--global-dark);
    transition: 0.5s;
  }

  .sub-header .sub-header-right {
    display: none;
  }

  /*Landing area container styles*/
  .landing-products-section {
    width: 99%;
    padding: 4rem 0.3rem;
  }

  /* Footer styles */
  .footer {
    padding-left: 0rem;
    flex-direction: column;
    column-gap: 1rem;
    width: 100%;
  }

  .footer-top {
    flex-direction: column;
    row-gap: 1rem;
    width: 100%;
  }

  .footer .footer-top .secondary-logo {
    width: 12rem;
    height: auto;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--global-gray);
    border-right: none;
  }

  .footer-top .footer-navigations {
    width: 95%;
    display: grid;
    grid-template-columns: 49% 49%;
    column-gap: 0.25rem;
    row-gap: 2rem;
    justify-items: center;
    align-content: center;
  }

  .footer-navigations .footer-sections {
    flex-direction: column;
    width: 48%;
  }

  .footer-delivery-locale {
    width: 90%;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    justify-content: center;
    align-items: center;
    height: max-content;
    padding-bottom: 2rem;
    border-bottom: 1px solid var(--global-gray);
  }

  .footer-delivery-locale .footer-links {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 1rem;
    column-gap: 0.8rem;
    justify-items: center;
    align-items: center;
    text-align: left;
  }
}
